import { InboxOutlined, UploadOutlined } from "@ant-design/icons";
import {
  Button,
  Divider,
  Input,
  Space,
  Upload,
  UploadProps,
  message,
} from "antd";
import Dragger from "antd/es/upload/Dragger";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { TypeAnimation } from "react-type-animation";

function UploadVideoScreen() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [form, setForm] = useState<{ title?: string; desc?: string }>({
    title: "",
    desc: "",
  });

  const [isUploading, setIsUploading] = useState<boolean>(false);

  const disableFileUpload = !(form.title?.length && form.desc?.length);

  const props: UploadProps = useMemo(() => {
    return {
      name: "file",
      multiple: false,
      action: `${process.env.REACT_APP_UTILS_API_URL}/videos/watermark`,
      method: "POST",
      data: {
        title: form.title,
        desc: form.desc,
        type: state.type,
      },
      onChange(info) {
        const { status } = info.file;

        if (status === "uploading") {
          setIsUploading(true);
        }
        if (status === "done") {
          message.success(`${info.file.name} file uploaded successfully.`);
          setForm({ title: "", desc: "" });
          setIsUploading(false);
          const { response } = info.file;
          setTimeout(() => {
            navigate("/upload-result", {
              state: {
                remoteOriginalVideoLink: response.remoteOriginalVideoLink,
                remoteWatermarkVideoLink: response.remoteWatermarkVideoLink,
              },
            });
          }, 2000);
        } else if (status === "error") {
          message.error(`${info.file.name} file upload failed.`);
          setIsUploading(false);
        }
      },
      onDrop(e) {
        console.log("Dropped files", e.dataTransfer.files);
      },
    };
  }, [form]);

  return (
    <div className="flex flex-col">
      <div className="w-full text-center pt-20 text-5xl font-extralight">
        <TypeAnimation
          sequence={["Let's start upload the video..."]}
          wrapper="span"
          speed={50}
          style={{ fontSize: "1em", display: "inline-block" }}
        />
      </div>

      <div className="pt-20 w-1/2 self-center flex flex-col">
        <Input
          className="mt-10"
          placeholder="Title"
          onChange={(titleObj) => {
            setForm({ ...form, title: titleObj?.target?.value });
          }}
          size="large"
        />
        <Divider />
        <Input
          placeholder="Description"
          onChange={(descObj) => {
            setForm({ ...form, desc: descObj?.target?.value });
          }}
          size="large"
        />
        <Divider />
        <Dragger {...props} disabled={disableFileUpload}>
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">
            Click or drag file to this area to upload
          </p>
          <p className="ant-upload-hint">Support for a single upload.</p>
        </Dragger>
        {isUploading && (
          <div className="text-xl font-mono mt-10">
            <TypeAnimation
              sequence={[
                "Uploading, please wait...",
                2000,
                "Uploading, it can take a while...",
                5000,
              ]}
              wrapper="span"
              speed={20}
              style={{ fontSize: "1em", display: "inline-block" }}
              repeat={Infinity}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default UploadVideoScreen;
