import React from "react";
import ButtonBox from "./button-box/button-box";
import { TypeAnimation } from "react-type-animation";
import { useNavigate } from "react-router-dom";
import "./home-screen.styles.css";

function HomeScreen() {
  const navigate = useNavigate();

  return (
    <div>
      <div className="w-full pt-20 text-5xl font-extralight flex flex-row justify-center">
        <div className="mr-3 font-mono">
          <TypeAnimation
            sequence={["LetsTok"]}
            wrapper="span"
            speed={50}
            style={{
              fontSize: "1em",
              display: "inline-block",
              color: "#F24462",
            }}
            cursor={false}
          />
        </div>
        <TypeAnimation
          sequence={[1000, "Marketplace Dashboard"]}
          wrapper="span"
          speed={50}
          style={{ fontSize: "1em", display: "inline-block" }}
          cursor={false}
        />
      </div>

      <div className="w-full text-center pt-32 text-3xl font-extralight">
        <TypeAnimation
          sequence={[2000, "What would you like to do today?"]}
          wrapper="span"
          speed={50}
          style={{ fontSize: "1em", display: "inline-block" }}
          cursor={false}
        />
      </div>
      <div className="flex justify-center items-center mt-20">
        <div className="flex flex-wrap justify-center gap-5">
          <div className="opacity-0 animate-fade-in ease-out duration-150 hover:scale-110 delay-200">
            <ButtonBox
              title="Marketplace Video"
              onClick={() => {
                navigate("/upload-video", {
                  state: {
                    type: "marketplace",
                  },
                });
              }}
            />
          </div>
          <div className="opacity-0 animate-fade-in ease-out duration-150 hover:scale-110 delay-200">
            <ButtonBox
              title="Inspiration Video"
              onClick={() => {
                navigate("/upload-video", {
                  state: {
                    type: "inspiration",
                  },
                });
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default HomeScreen;
