import React from "react";
import "./App.css";
import HomeScreen from "./screens/home-screen/home-screen";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import UploadVideoScreen from "./screens/upload-video-screen/upload-video-screen";
import UploadResultScreen from "./screens/upload-result-screen/upload-result-screen";
import { ConfigProvider } from "antd";

function App() {
  return (
    <ConfigProvider
      theme={{
        components: { 
          // Button: {
          //   colorPrimaryHover:'#F24462'
          // },
          // Input:{
          //   colorPrimaryHover:'#F24462',
          //   activeBorderColor:'#F24462'
          // },
          // Upload:{
          //   colorPrimaryHover:'#F24462',
          // }
        },
      }}
      // button={{style:{}}}
      // button={{style:{borderColor:'red'}}}
    >
      <BrowserRouter>
        <Routes>
          {/* <Route path="/video-player-iframe" Component={VideoPlayerIframe} /> */}
          <Route path="/upload-result" Component={UploadResultScreen} />
          <Route path="/upload-video" Component={UploadVideoScreen} />
          <Route path="/*" Component={HomeScreen} />
        </Routes>
      </BrowserRouter>
    </ConfigProvider>
  );
}

export default App;
