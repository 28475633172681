import { CopyFilled, CopyOutlined } from "@ant-design/icons";
import { Button, Divider, Input, message } from "antd";
import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { TypeAnimation } from "react-type-animation";

function UploadResultScreen() {
  const location = useLocation();
  const navigate = useNavigate();
  const params: {
    remoteOriginalVideoLink: string;
    remoteWatermarkVideoLink: string;
  } = location?.state;

  if (
    !params?.remoteOriginalVideoLink?.length &&
    !params?.remoteWatermarkVideoLink?.length
  )
    return (
      <div className="flex flex-col items-center w-full">
        <div className="text-4xl mt-20 font-extralight">Oops!</div>
        <div className="mt-10 font-mono text-2xl">
          <TypeAnimation sequence={["The file was falied to uploaded"]} />
        </div>

        <div className="mt-20">
          <Button
            size="large"
            onClick={() => {
              navigate("/upload-video");
            }}
          >
            Back
          </Button>
        </div>
      </div>
    );
  const handleCopy = (link: string) => {
    message.success("Video link copied");
    navigator.clipboard.writeText(link);
  };
  return (
    <div className="flex flex-col w-full items-center">
      <div className="text-5xl mt-20 font-extralight">Great!</div>
      <div className="text-4xl mt-10 font-extralight">
        <TypeAnimation sequence={["The file was successfully uploaded"]} />
      </div>

      <div className="mt-20 w-full items-center flex flex-col">
        <div className="font-mono">Original</div>
        <Input
          prefix={
            <CopyOutlined
              onClick={() => handleCopy(params.remoteOriginalVideoLink)}
            />
          }
          className="w-3/5"
          size="large"
          readOnly
          onClick={() => handleCopy(params.remoteOriginalVideoLink)}
          value={params.remoteOriginalVideoLink}
        />
        <div className="w-3/5">
          <Divider />
        </div>
        <div className="font-mono">Watermark</div>
        <Input
          prefix={
            <CopyOutlined
              onClick={() => handleCopy(params.remoteWatermarkVideoLink)}
            />
          }
          className="w-3/5"
          size="large"
          readOnly
          onClick={() => handleCopy(params.remoteWatermarkVideoLink)}
          value={params.remoteWatermarkVideoLink}
        />
      </div>
      <div className="mt-10 font-mono">
        The links above will be available soon
      </div>

      <div className="mt-20">
        <Button
          size="large"
          onClick={() => {
            navigate("/");
          }}
        >
          Done
        </Button>
      </div>
    </div>
  );
}

export default UploadResultScreen;
