import { Button } from "antd";
import React from "react";
import IButtonBox from "./interfaces";

function ButtonBox({ title, ...props }: IButtonBox) {
  return (
    <Button shape="round" style={{ height: 140, width: 220 }} {...props} >
      <Title text={title || ""} />
      {props.children}
    </Button>
  );
}

export default ButtonBox;

const Title = ({ text = "" }: { text: string }) => {
  return <div className="text-2xl font-extralight">{text}</div>;
};
